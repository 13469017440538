/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

.product-avatar {
  height: 50px;
  width: 50px;
  background: #f2ca27;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 8px;
  color: #000;
  font-size: 1.5rem;
}


cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}


.pb-10 {
  padding-bottom: 10rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.p-25 {
  padding: 25px;
}

img {
  display: initial !important;
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.count-badge-list {
  ion-chip {
    display: flex;
    max-width: 170px;
  }
  ion-text {
    white-space: nowrap;

    ion-note {
      display: block;
      padding: 4px;
    }
  }
}

.ion-text-bold{
  font-weight: 700;
}


.p-0{
  padding: 0 !important;
}


@media (prefers-color-scheme: dark) {
  .ngx-pagination a, .ngx-pagination button {
    color: #ffffff !important;
  }
}

.ngx-pagination {
  margin-bottom: 5rem !important; 
}

.ngx-pagination .current {
  background: #f1c40f !important;
  color: #000000 !important;
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
  background: #f1c40f !important;
  color: black !important;
}


pagination-controls{
  text-align: center;
}
@media screen and (min-height: 320px) and (max-height : 667px) {
  ion-title{
  text-align: left !important;
  padding:15px;

  }
}